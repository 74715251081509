export default {
  colors: {
    primary: 'rgba(22, 27, 51, 1)',
    secondary: 'rgba(0, 26, 35, 1)',
    secondaryLighter: 'rgba(22, 27, 51, 0.5)',
    secondaryTransparent: 'rgba(225, 231, 253, 0.75)',
    white: 'rgba(250, 250, 250, 1)',
    whiteTransparent: 'rgba(250, 250, 250, 0.95)',
    lightGrey: 'rgba(206, 206, 206, 0.5)',
    offWhite: 'rgba(250, 250, 250, 1)',
    success: 'rgba(126, 211, 33, 1)',
    error: 'rgba(227, 47, 69, 1)'
  },
  spacing: {
    x1: '.25rem',
    x2: '.5rem',
    x3: '1rem',
    x4: '2rem',
    x5: '4rem',
    x6: '8rem',
    x7: '16rem'
  },
  fontFamily: {
    sanSerif: `'avenir next', avenir, 'century gothic', sans-serif`
  },
  fontScale: {
    x1: '0.75rem',
    x2: '0.875rem',
    x3: '1rem',
    x4: '1.25rem',
    x5: '1.5rem',
    x6: '2.25rem',
    x7: '3rem',
    x8: '5rem'
  },
  fontWeights: {
    normal: 400,
    bold: 600
  },
  lineHeights: {
    body: 1.7,
    heading: 1.25,
    none: 1
  },
  radii: [4],
  breakpoints: {
    notSmall: 'min-width: 30em',
    mediumAndUp: 'min-width: 60em',
    medium: 'min-width: 30em and max-width: 60em',
    large: 'min-width: 60em'
  }
}
