import React, { useState, useContext } from 'react'

import { post } from '../lib/network'
import { readVoteLocally, writeVoteLocally } from '../lib/utils'

import NotVoted from '../components/NotVoted'
import Voted from '../components/Voted'
import { CompetitionContext } from '../screens/Competition'

const Voting = ({ competitionId }) => {
  const localVote = readVoteLocally(competitionId)

  const [vote, setVote] = useState(localVote)
  const [loading, setLoading] = useState(false)
  const [selectedIds, setSelectedIds] = useState(
    (localVote && localVote.voteIds) || new Array(3)
  )
  const [error, setError] = useState(null)
  const { data, handleOrderBy, handleFocusOnPlayer, orderBy } = useContext(
    CompetitionContext
  )

  const allAthletes = data.map(({ resultId, simulationCompetitorRecord }) => ({
    resultId,
    ...simulationCompetitorRecord
  }))

  const [athletes, setAthletes] = useState(allAthletes)

  const isValidVote = ({ voteIds }) => {
    const isValid = voteIds.filter(id => id).length !== 0
    const errorMsg = 'To vote you need to choose your predicted top 3.'
    setError(isValid ? null : errorMsg)
    return isValid ? true : false
  }

  const persistVote = voteData =>
    post('/api/v1/competition_simulation_votes', {
      setLoading,
      setError,
      setData: data => {
        writeVoteLocally(competitionId, voteData)
        setVote(voteData)
      },
      payload: {
        competitionSimulationId: voteData.competitionId,
        rank_1ResultId: voteData.voteIds[0],
        rank_2ResultId: voteData.voteIds[1],
        rank_3ResultId: voteData.voteIds[2]
      }
    })

  const handleVote = e => {
    e.preventDefault()
    const voteData = { competitionId, voteIds: selectedIds }
    const valid = isValidVote(voteData)
    if (valid) persistVote(voteData)
  }

  const handleSelectedIds = (athlete, position) => {
    selectedIds[position - 1] = athlete.resultId
    setSelectedIds(selectedIds)
    setAthletes(allAthletes.filter(a => !selectedIds.includes(a.resultId)))
  }

  return vote ? (
    <Voted
      data={data}
      handleOrderBy={handleOrderBy}
      handleFocusOnPlayer={handleFocusOnPlayer}
      orderBy={orderBy}
    >
      Voted
    </Voted>
  ) : (
    <NotVoted
      handleVote={handleVote}
      handleSelectedIds={handleSelectedIds}
      athletes={athletes}
      error={error}
      loading={loading}
    />
  )
}

export default Voting
