export const competitionName = (competition) =>
  `${competition.competitionName} ${competition.venueName}`

export const currentPhaseSimulations = (phaseSimulations) =>
  phaseSimulations ? phaseSimulations[phaseSimulations.length - 1] : null

export const extractCompetitorData = (data) => ({
  athleteId: data.athleteFeiId,
  athleteName: data.athleteName,
  horseId: data.horseFeiId,
  horseName: data.horseName
})

export const extractProbData = (data) =>
  data.map(({ entry, simWinProb, simTop3Prob, simTop10Prob }) => ({
    ...extractCompetitorData(entry),
    one: simWinProb,
    two: simTop3Prob,
    three: entry.sacModel.clearRoundProbability
  }))

export const extractMoveData = (data) =>
  console.log('MOVE DATA', data) ||
  data.map(
    ({
      entry,
      simulationCompetitorRecord,
      simWinMove,
      simTop3Move,
      simTop10Move
    }) => ({
      ...extractCompetitorData(simulationCompetitorRecord),
      resultId: entry.id,
      one: simWinMove,
      two: simTop3Move,
      three: simTop10Move
    })
  )
