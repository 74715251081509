import React from 'react'

import DataView from '../components/DataView'
import { extractCompetitorData } from '../lib/helpers'

const extractResultsData = data =>
  data.map(({ resultId, simulationCompetitorRecord }) => ({
    ...extractCompetitorData(simulationCompetitorRecord),
    resultId: resultId,
    one: simulationCompetitorRecord.competitorVotesRank1Percentage,
    two: simulationCompetitorRecord.competitorVotesRank2Percentage,
    three: simulationCompetitorRecord.competitorVotesRank3Percentage
  }))

const Voted = ({ data, handleOrderBy, handleFocusOnPlayer, orderBy }) => {
  const results = extractResultsData(data)
  return (
    <DataView
      headings={['1st', '2nd', '3rd']}
      type="Prob"
      data={results}
      handleOrderBy={handleOrderBy}
      handleFocusOnPlayer={handleFocusOnPlayer}
      orderBy={orderBy}
    />
  )
}

export default Voted
