import React from 'react'
import styled, { withTheme } from 'styled-components'

import Text from './Text'
import Spacer from './Spacer'
import Link, { WidgetLink } from './Link'

const Section = styled.section`
  margin: 0 ${(props) => props.theme.spacing.x3};
`
const Introduction = ({ theme, embed }) => {
  return (
    <Section>
      <Spacer size={theme.spacing.x4} />
      <Text
        tag="h1"
        size={[theme.fontScale.x5]}
        weight={theme.fontWeights.bold}
        lineHeight={theme.lineHeights.heading}
      >
        Welcome to the EquiRatings Jumping Prediction Centre
      </Text>
      <Spacer size={theme.spacing.x1} />
      <Text tag="p" size={[theme.fontScale.x3]}>
        EquiRatings has designed a statistical model
        that forecasts the results of major jumping competitions before and
        after the event.
      </Text>
      <Spacer size={theme.spacing.x1} />
      <Text tag="p" size={[theme.fontScale.x3]} weight={theme.fontWeights.bold}>
        See how it works:{' '}
        {embed ? (
          <WidgetLink href="/how-our-predictions-work" target="_blank">
            Our analysis explained
          </WidgetLink>
        ) : (
          <Link to="/how-our-predictions-work">Our analysis explained</Link>
        )}
      </Text>
    </Section>
  )
}

export default withTheme(Introduction)
