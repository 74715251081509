import React from 'react'
import { ThemeProvider } from 'styled-components'
import { Router } from '@reach/router'

import theme from './lib/theme'

import Home from './screens/Home'
import Competition from './screens/Competition'
import Layout from './components/Layout'
import Leaderboard from './screens/Leaderboard'
import MovementBoard from './screens/MovementBoard'
import Voting from './screens/Voting'

const App = ({ embed }) => {
  return (
    <ThemeProvider theme={theme}>
      <Layout embed={embed}>
        <Router primary={false}>
          <Home path="/" />
          <Competition path="/competitions/:competitionId" embed={embed}>
            <Leaderboard path="/" />
            <MovementBoard path="movement" />
            <Voting path="voting" />
          </Competition>
        </Router>
      </Layout>
    </ThemeProvider>
  )
}

export default App
