import Cookies from 'universal-cookie'
import addYears from 'date-fns/add_years'

export const sortBy = (arr, attr) => {
  const first = attr
  const second = attr === 'one' ? 'two' : 'one'
  const third = attr === 'three' ? 'two' : 'three'

  return arr.sort((a, b) => {
    if (a[first] < b[first]) return 1
    if (a[first] > b[first]) return -1
    if (a[second] < b[second]) return 1
    if (a[second] > b[second]) return -1
    if (a[third] < b[third]) return 1
    if (a[third] > b[third]) return -1
    return 0
  })
}

export const toPercentage = value =>
  value < 0.01 ? `< 1%` : `${(value * 100).toFixed(0)}%`

export const toMovementPercentage = value => `${(value * 100).toFixed(0)}%`

export const movementToOpacity = value => {
  const absVal = Math.abs(value)
  return absVal > 0.25 ? 1 : absVal * 4
}

// TODO: Currently this is too coupled to the movement screen
export const opacifyColor = (color, opacity) => {
  const a = movementToOpacity(opacity)
  const rgb = color
    .split('rgba(')[1]
    .split(')')[0]
    .split(',')
    .map(el => el.trim())
    .slice(0, 3)
  const rgba = rgb.concat(a)
  return `rgba(${rgba.join(', ')})`
}

export const findAncestor = function(el, sel) {
  while (
    (el = el.parentElement) &&
    !(el.matches || el.matchesSelector).call(el, sel)
  );
  return el
}

export const numToWord = num => {
  switch (num) {
    case 1:
      return 'one'
    case 2:
      return 'two'
    case 3:
      return 'three'
    default:
      return 'zero'
  }
}

const cookies = new Cookies()

const isLocalStorage = () => {
  var mod = 'check'
  try {
    localStorage.setItem(mod, mod)
    localStorage.removeItem(mod)
    return true
  } catch (e) {
    return false
  }
}

export const readVoteLocally = competitionId => {
  const voteName = `vote-${competitionId}`
  return isLocalStorage()
    ? JSON.parse(localStorage.getItem(voteName))
    : cookies.get(voteName)
}

export const writeVoteLocally = (competitionId, voteData) => {
  const voteName = `vote-${competitionId}`
  if (isLocalStorage()) {
    localStorage.setItem(voteName, JSON.stringify(voteData))
  } else {
    const expires = addYears(new Date(), 5)
    cookies.set(voteName, voteData, { expires })
  }
}
