import React from 'react'
import styled from 'styled-components'

import theme from '../lib/theme'
import {
  toPercentage,
  toMovementPercentage,
  movementToOpacity,
  opacifyColor
} from '../lib/utils'

const valueToOpacity = (value) => (value <= 0.5 ? value * 2 : 1)

const Prob = styled.td`
  font-family: ${theme.fontFamily.sanSerif};
  font-size: ${theme.fontScale.x4};
  color: ${({ opacity }) =>
    opacity > 0.9 ? theme.colors.primary : theme.colors.secondary};
  width: 17%;
  background: rgba(141, 106, 159, ${({ opacity }) => opacity});
  text-align: center;
`

const Move = styled.td`
  font-family: ${theme.fontFamily.sanSerif};
  font-size: ${theme.fontScale.x4};
  color: ${theme.colors.secondary};
  width: 17%;
  background: ${({ value, theme }) =>
    opacifyColor(value > 0 ? theme.colors.success : theme.colors.error, value)};
  text-align: center;
`

const Val = styled.span`
  opacity: ${({ opacity }) => (opacity < 0.01 ? 0.2 : 1)};
`

const displayDiff = (value) => {
  const diffWithPercentage = `${(value * 100).toFixed(0)}%`
  if (!value) {
    return ''
  } else if (value > 0.01) {
    return ` +${diffWithPercentage}`
  } else if (value < 0) {
    return ` ${diffWithPercentage}`
  } else {
    return ` <${diffWithPercentage}`
  }
}

const DataProbability = ({ type, value }) =>
  type === 'Prob' ? (
    <Prob opacity={valueToOpacity(value)}>
      <Val opacity={value}>{toPercentage(value)}</Val>
    </Prob>
  ) : (
    <Move value={value}>
      <Val opacity={movementToOpacity(value)}>{displayDiff(value)}</Val>
    </Move>
  )

export default DataProbability
