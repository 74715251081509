import React from 'react'
import styled from 'styled-components'

import CompetitionsListItem from './CompetitionListItem'

const Ul = styled.ul`
  list-style: none;
  padding: 0;
`

const CompetitionsList = ({ competitions }) =>
  console.log('COMPS HERE', competitions) || (
    <section>
      <Ul>
        {competitions.map((competition, idx) => (
          <CompetitionsListItem key={competition.id} competition={competition} />
        ))}
      </Ul>
    </section>
  )

export default CompetitionsList
