import React from 'react'
import styled from 'styled-components'

import theme from '../lib/theme'
import { sortBy, numToWord } from '../lib/utils'
import DataProbability from './DataProbability'
import DataName from './DataName'
import DataHeading from './DataHeading'

const DataTable = styled.table`
  table-layout: fixed;
  width: 100%;
  & td {
    padding: 0;
  }
`

const Container = styled.div`
  margin-left: ${theme.spacing.x3};
`

const DataView = ({
  headings,
  type,
  data,
  handleOrderBy,
  orderBy,
  handleFocusOnPlayer
}) => {
  if (!data) return null

  const orderedData = sortBy(data, orderBy)

  return (
    <Container>
      <DataTable cellSpacing="0" cellPadding="0">
        <thead>
          <tr>
            <DataHeading
              handleOrderBy={handleOrderBy}
              value="Combination"
              orderBy={orderBy}
            />
            {headings.map((heading, idx) => (
              <DataHeading
                key={idx}
                handleOrderBy={handleOrderBy}
                value={heading}
                orderBy={orderBy}
                field={numToWord(idx + 1)}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {orderedData.map((datum, idx) => (
            <tr
              key={idx}
              onClick={(e) =>
                // handleFocusOnPlayer && handleFocusOnPlayer(datum.athleteId, e)
                console.log('Movements coming soon')
              }
              className="row"
            >
              <DataName datum={datum} />
              <DataProbability type={type} value={datum.one} />
              <DataProbability type={type} value={datum.two} />
              <DataProbability type={type} value={datum.three} />
            </tr>
          ))}
        </tbody>
      </DataTable>
    </Container>
  )
}

DataView.defaultProps = {
  handleOrderBy: () => {},
  handleFocusOnPlayer: () => {}
}

export default DataView
