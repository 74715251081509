import React, { useContext } from 'react'

import DataView from '../components/DataView'
import { CompetitionContext } from '../screens/Competition'

import { extractMoveData } from '../lib/helpers'

const MovementBoard = () => {
  const { data, handleOrderBy, handleFocusOnPlayer, orderBy } = useContext(
    CompetitionContext
  )
  const results = extractMoveData(data)
  return (
    <DataView
      headings={['Win', 'Top 3', 'Clear Chance']}
      type="Move"
      data={results}
      handleOrderBy={handleOrderBy}
      handleFocusOnPlayer={handleFocusOnPlayer}
      orderBy={orderBy}
    />
  )
}

export default MovementBoard
