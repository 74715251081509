import React, { useState, useEffect, createContext } from 'react'
import styled, { withTheme } from 'styled-components'
import { CSSTransition } from 'react-transition-group'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from 'body-scroll-lock'

import { get } from '../lib/network'
import { competitionName, currentPhaseSimulations } from '../lib/helpers'
import { findAncestor } from '../lib/utils'

import ErrorMessage from '../components/ErrorMessage'
import Loading from '../components/Loading'
import Text from '../components/Text'
import Spacer from '../components/Spacer'
import PlayerView from '../components/PlayerView'
import CompetitionNav from '../components/CompetitionNav'
import Introduction from '../components/Introduction'
import Link, { WidgetLink } from '../components/Link'
import Chevron from '../components/Chevron'
const Padding = styled.div`
  padding: 0 ${(props) => props.theme.spacing.x3};
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`

const CompetitionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
`

export const CompetitionContext = createContext(null)

const Competition = ({ competitionId, theme, children, embed }) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [orderBy, setOrderBy] = useState('one')
  const [modal, setModal] = useState({
    focusedPlayerID: null,
    modalStartPosition: null
  })

  useEffect(() => {
    get('/api/v1/competition_simulations/' + competitionId, {
      setLoading,
      setError,
      setData
    })
  }, [])

  useEffect(() => clearAllBodyScrollLocks, [])

  const handleFocusOnPlayer = (id, e) => {
    console.log(id, e)
    e.preventDefault()
    const targetElement = document.querySelector('#player-view')
    id ? disableBodyScroll(targetElement) : enableBodyScroll(targetElement)

    const parent = findAncestor(e.target, '.row')
    const pos = parent ? parent.getBoundingClientRect() : null
    setModal((prevState) => ({
      modalStartPosition: pos || prevState.modalStartPosition,
      focusedPlayerID: id
    }))
  }

  const currentPhaseSimulation =
    data && currentPhaseSimulations(data.roundSimulations)

  return (
    console.log(currentPhaseSimulation) || (
      <>
        {error && <ErrorMessage error={error} />}
        {loading || !currentPhaseSimulation ? (
          <Loading />
        ) : (
          <>
            {embed ? <Introduction embed={embed} /> : null}
            <CompetitionContext.Provider
              value={{
                data: currentPhaseSimulation.resultSimulations,
                handleOrderBy: setOrderBy,
                orderBy,
                handleFocusOnPlayer
              }}
            >
              <Spacer size={theme.spacing.x4} />
              <Padding>
                <CompetitionContainer>
			<div style={{display: 'flex', alignItems: 'center' }}>
				<Link to={embed ? '' : '/'} aria-label="Link to EquiRatings" style={{display: 'flex'}}>
			<Chevron />
                  </Link>
                  <Spacer size={theme.spacing.x2} />
                  <Text
                    tag="h1"
                    size={[theme.fontScale.x5]}
                    weight={theme.fontWeights.bold}
                    lineHeight={theme.lineHeights.heading}
                    cursor="pointer"
                  >
						{competitionName(data)}
                  </Text>
			</div>
                  <Spacer size={theme.spacing.x4} />
                  <Text
                    tag="h1"
                    size={[theme.fontScale.x4]}
                    weight={theme.fontWeights.normal}
                    lineHeight={theme.lineHeights.heading}
                    cursor="pointer"
                  >
                    {data.competitionArticleUrl ? (
                      <WidgetLink
                        href={data.competitionArticleUrl}
                        target="_blank"
                      >{`Read the SAP Eventing Insights blog here »`}</WidgetLink>
                    ) : null}
                  </Text>
                </CompetitionContainer>
                <Spacer size={theme.spacing.x4} />
                <Row>
                  <CompetitionNav
                    phase={currentPhaseSimulation.round}
                    competitionId={competitionId}
                  />
                </Row>
              </Padding>
              <Spacer size={theme.spacing.x4} />
              <Spacer size={theme.spacing.x3} />
              {children}
              <Spacer size={theme.spacing.x3} />
              <CSSTransition
                in={modal.focusedPlayerID !== null}
                classNames="modal"
                timeout={600}
              >
                <PlayerView
                  playerID={modal.focusedPlayerID}
                  data={data}
                  handleFocusOnPlayer={handleFocusOnPlayer}
                  modalStartPosition={modal.modalStartPosition}
                />
              </CSSTransition>
            </CompetitionContext.Provider>
          </>
        )}
      </>
    )
  )
}

export default withTheme(Competition)
