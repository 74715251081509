import React, { Fragment, useState, useEffect } from 'react'

import { get } from '../lib/network'

import Introduction from '../components/Introduction'
import CompetitionsList from '../components/CompetitionsList'
import ErrorMessage from '../components/ErrorMessage'
import Loading from '../components/Loading'

const Home = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    get('/api/v1/featured_competition_simulations', {
      setLoading,
      setError,
      setData
    })
  }, [])

  return (
    <Fragment>
      <Introduction />
      {error && <ErrorMessage error={error} />}
      {loading || !data ? (
        <Loading />
      ) : (
        <CompetitionsList competitions={data.featuredCompetitionSimulations} />
      )}
    </Fragment>
  )
}

export default Home
