import React from 'react'
import styled, { withTheme } from 'styled-components'

import {
  competitionName,
  currentPhaseSimulations,
  extractProbData
} from '../lib/helpers'

import Text from './Text'
import Spacer from './Spacer'
import Link, { WidgetLink } from './Link'
import { BlockLink } from './Link'
import DataView from './DataView'

const CompetitionsListItemContainer = styled.li`
  padding: ${(props) => props.theme.spacing.x3} 0;
  margin: ${(props) => props.theme.spacing.x4} 0;
  background: ${(props) => props.theme.colors.offWhite};
`

const Padding = styled.div`
  padding: 0 ${(props) => props.theme.spacing.x3};
`

const CompetitionTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`

const CompetitionsListItem = ({ competition, theme }) => {
  console.log(competition)
  const currentPhaseSimulation = currentPhaseSimulations(
    competition.roundSimulations
  )
  console.log('CPS', currentPhaseSimulation)
  const results = extractProbData(currentPhaseSimulation.top3ResultSimulations)
  return (
    console.log(competition.id) || (
      <CompetitionsListItemContainer>
        <Padding>
          <CompetitionTitleContainer>
            <Text
              tag="h1"
              size={[theme.fontScale.x5]}
              weight={theme.fontWeights.bold}
              lineHeight={theme.lineHeights.heading}
              cursor="pointer"
            >
              <Link to={`/competitions/${competition.id}`}>
                {competitionName(competition)}
              </Link>
            </Text>
            {competition.competitionArticleUrl ? (
              <>
                <Spacer size={theme.spacing.x3} />
                <Text
                  tag="h1"
                  size={[theme.fontScale.x4]}
                  weight={theme.fontWeights.normal}
                  lineHeight={theme.lineHeights.heading}
                  cursor="pointer"
                >
                  <WidgetLink
                    href={competition.competitionArticleUrl}
                    target="_blank"
                  >{`Read the SAP Eventing Insights blog here »`}</WidgetLink>
                </Text>
              </>
            ) : null}
          </CompetitionTitleContainer>
        </Padding>
        <Spacer size={theme.spacing.x3} />

        <BlockLink
          to={`/competitions/${competition.id}`}
          aria-label={`Link to the ${competition.venueName} predicted leaderboard`}
        >
          <Spacer size={theme.spacing.x3} />
          <DataView
            headings={['Win', 'Top 3', 'Clear Chance']}
            type="Prob"
            data={results}
          />
          <Spacer size={theme.spacing.x3} />
          <Padding>
            <Text
              tag="p"
              size={[theme.fontScale.x3]}
              weight={theme.fontWeights.bold}
              lineHeight={theme.lineHeights.heading}
              cursor="pointer"
              align="right"
            >
              View leaderboard »
            </Text>
          </Padding>
        </BlockLink>
      </CompetitionsListItemContainer>
    )
  )
}

export default withTheme(CompetitionsListItem)
