import { camelizeKeys, decamelizeKeys } from 'humps'

const defaultOpts = {
  headers: {
    'Access-Control-Allow-Origin': '*'
  }
}

const baseURL =
  'https://jumping-prediction-centre-api-prod.cfapps.eu10.hana.ondemand.com'

const fetchOpts = (method, opts) => {
  const baseOpts = {
    method: 'GET',
    mode: 'cors',
    headers: { ...defaultOpts.headers, ...opts.headers }
  }

  return method === 'GET'
    ? baseOpts
    : {
        ...baseOpts,
        headers: { ...baseOpts.headers, 'Content-Type': 'application/json' },
        method,
        body: opts.payload
      }
}

const resetForRequest = (opts) => {
  opts.setLoading && opts.setLoading(true)
  opts.setError && opts.setError(null)
}

const finishRequest = (opts) => {
  opts.setLoading && opts.setLoading(false)
}

const makeRequest = async (method, url, opts) => {
  try {
    const response = await fetch(baseURL + url, fetchOpts(method, opts))
    return response
  } catch (err) {
    opts.setError(err)
  }
}

export const get = async (url, opts = defaultOpts) => {
  resetForRequest(opts)
  const response = await makeRequest('GET', url, opts)
  const json = await response.json()
  const data = camelizeKeys(json)
  opts.setData(data)
  finishRequest(opts)
}

export const post = async (url, opts = defaultOpts) => {
  resetForRequest(opts)
  opts.payload = JSON.stringify(decamelizeKeys(opts.payload)) // NOTE: Mutation going on here.
  const response = await makeRequest('POST', url, opts)
  const json = await response.json()
  const data = camelizeKeys(json)
  if (response.status < 300) {
    opts.setData(data)
  } else {
    opts.setError && opts.setError(JSON.stringify(data.message))
  }
  finishRequest(opts)
}
